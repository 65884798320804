.pending-payments {
  background: white;
  justify-content: center;
  margin-top: 2rem;

  .label-cost {
    margin-top: 3.25rem;
    justify-content: space-between;
  }

  .label-cost-b {
    color: #652d87;
    /* Primary/2XL */
    font-family: Raleway;
    font-size: 35.154px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 43.942px */
  }
  .btn-view-all {
    display: flex;
    padding: 28px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .balance-card,
  .payment-plan-card {
    margin-top: 3.25rem;
    background: #fef1e6;
    border-radius: 2px;

    .ant-card-body {
      padding: 32px;
    }
    .balance-text {
      color: #92616f;
      font-size: 14.4px;
    }

    h1 {
      margin-top: 0;
      color: #312025;
      span:first-child {
        font-size: 48px;
        color: #92616f;
      }
      span:last-child {
        font-size: 32px;
        color: #312025;
      }
    }

    .ant-row {
      gap: 1.5rem;

      .ant-btn {
        padding: 24px 16px;
      }

      .ant-btn-default {
        background: transparent;
      }
    }

    .pay-btn {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
  .payment-plan-main .balance-card {
    height: 90%;
    .balance-num {
      margin-top: auto;
    }
    .balance-text {
      align-self: start;
      font-family: Lato;
      font-size: 11.52px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 17.28px */
    }
    .balance-text-2 {
      color: #000;
      align-self: start;
      margin-top: 0.2rem;
    }
    .ant-card-body {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      height: 100%;

      .pay-btn btn {
        margin-top: 2.2rem;
      }

      .ant-row {
        margin-top: auto;
        width: 100%;
      }
    }
  }

  .payment-plan-card {
    .balance-text {
      font-family: Lato;
      font-size: 11.52px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 17.28px */
    }
    .pay-btn button {
      padding: 24px 16px;
      margin-bottom: -0.86rem;
      margin-top: 2rem;
    }
  }
}

@media (max-width: 576px) {
  .pending-payments {
    .label-cost {
      a {
        margin-top: 1rem;
      }
      a,
      .ant-btn {
        width: 100%;
      }
    }
    .balance-card,
    .payment-plan-card {
      text-align: center;
      margin: 3rem auto;

      .ant-row {
        gap: 1.5rem;
        justify-content: center;
      }

      a {
        width: 100%;
        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}

.payment-plan-card {
  height: 90%;

  .title-text {
    color: #000;
  }

  .label-balance {
    font-size: 18px;
  }

  .label-fract {
    font-size: 14px;
  }

  .row-amount {
    flex-direction: column;
    align-items: center;

    .title-text-2 {
      margin-bottom: unset;
    }
  }
}
