@import "./mediaQuery.scss";

.ant-btn {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back-btn {
  display: flex;
  align-items: center;
  color: #92616f;
  font-family: Lato;
  font-size: 14.4px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21.6px */
  cursor: pointer;
  width: fit-content;
  span {
    margin-left: 12px;
  }
  margin-bottom: 1rem;
}

.word-break-2 {
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rive-loader {
  height: "100px";
  transform: translate(0%, 45vh);
}

.modal-timer-btn {
  height: 59px;
  width: 100%;
}

.d-none {
  display: none;
}
