.contact-info {
  background: white;
  justify-content: center;
  margin-top: 2rem;

  .address {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: #281236;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
  }

  .contact-form {
    margin-top: 2.5rem;

    .ant-form-item-label label {
      color: #61414a;
      font-family: Lato;
      font-size: 14.4px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21.6px */
    }

    .ant-btn {
      background: #f3a2b9;
    }

    .react-tel-input {
      input {
        border-color: #f5b5c7;
        height: 60px;
        width: 100%;
        color: #92616f;
        font-size: 18px;
        line-height: 0.972;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      }
      .flag-dropdown {
        border-color: #f5b5c7;
        background-color: #fff;
      }
    }
  }

  .input-email {
    padding: 17px;
  }

  .form-btn {
    width: 100%;
    padding: 29px;
    font-weight: 500;
  }
}
