.payment-success,
.payment-failed {
  background: white;
  justify-content: center;
  margin-top: 6.31rem;
  margin-bottom: 2rem;

  .confirmation-block {
    margin-top: 3.5rem;
  }

  .confirmation-btn {
    margin-top: 2.75rem;
    gap: 2rem;
    .ant-btn {
      padding: 24px 16px;
      min-width: 304px;
    }
  }

  .back-payment-btn .ant-btn {
    min-width: 100%;
    margin-top: 3.25rem;
    padding: 24px 16px;
  }

  .ant-form {
    margin-top: 2.5rem;
    .ant-form-item-control-input-content {
      .ant-checkbox-wrapper span:last-child {
        color: #000;
        /* Primary/MD */
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        padding-inline-start: 16px;
      }
    }

    .ant-form-item {
      margin-bottom: 1rem;
    }

    .checkbox-input {
      transition: all 1ms;
      span {
        color: #61414a;
        /* Primary/SM */
        font-family: Lato;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21.6px */
      }
      .ant-input {
        margin-top: 0.5rem;
      }
    }

    .react-tel-input {
      input {
        border-color: #f5b5c7;
        height: 38.6px;
        width: 100%;
        color: #92616f;
        font-size: 18px;
        line-height: 0.972;
        list-style: none;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      }
      .flag-dropdown {
        border-color: #f5b5c7;
        background-color: #fff;
      }
    }
  }
}

.payment-failed {
  margin-top: 25vh;
}

@media (max-width: 576px) {
  .payment-success,
  .payment-failed {
    margin-top: 2rem;

    .confirmation-block {
      margin-top: 1.5rem;
    }

    .confirmation-btn {
      margin-top: 2.25rem;
      .ant-btn {
        width: 100%;
      }
    }
  }

  .payment-failed {
    margin-top: 20vh;
  }
}
