.customise-payments {
  background: white;
  justify-content: center;
  margin-top: 2rem;

  .btn-pay {
    width: 100%;
    padding: 24px 16px;
  }

  .table-standard-card {
    width: 100%;
    margin-top: 3.25rem;
    background: #fef1e6;
    border-radius: 2px;

    thead {
      .ant-table-cell {
        font-weight: 800 !important;
      }
    }

    .ant-table-title {
      color: #312025;
      background: #fef1e6;
      font-size: 35.154px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
    }

    .ant-table-cell {
      background: #fef1e6;
      font-size: 14.4px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #312025;
      border-bottom-color: transparent;
    }

    .ant-table-cell-row-hover {
      background: #fef1e6 !important;
    }

    .ant-table-thead {
      .ant-table-cell {
        font-size: 14.4px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: #92616f;
        border-bottom-color: #f5b5c7;
      }
    }

    & :last-of-type.ant-table-row {
      .ant-table-cell {
        font-size: 18px;
      }
      & :last-child {
        text-align: end;
        color: #61414a;
        font-family: Lato;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }

    .ant-table-tbody :nth-last-child(2) {
      // display: none;
      .ant-table-cell {
        border-bottom-color: #f5b5c7;
      }
    }

    .ant-table-footer {
      background: #fef1e6;
      margin-top: -1.5rem;
    }
    .table-footer {
      display: flex;
      flex-direction: column;
      align-content: flex-end;
      text-align: end;
      .total-payment {
        span {
          color: #92616f;
        }
        font-size: 48px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .label-left-to-pay {
        margin-top: 1rem;
        color: #92616f;
      }
      .left-to-pay-amount {
        color: #92616f;
        font-size: 24px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .ant-btn {
        margin-top: 1rem;
      }
    }

    .ant-row {
      gap: 0.5rem;

      .ant-btn {
        padding: 24px 16px;
      }

      .ant-btn-default {
        background: transparent;
      }
    }
  }
}

.table-payment-plan-card {
  width: 100%;
  margin-top: 3.25rem;
  background: #fef1e6;
  border-radius: 2px;

  thead {
    .ant-table-cell {
      font-weight: 800 !important;
    }
  }

  .ant-table-title {
    color: #312025;
    background: #fef1e6;
    font-size: 35.154px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }

  .ant-table-expanded-row,
  .ant-table-cell {
    background: #fef1e6 !important;
  }
  .ant-table-cell {
    background: #fef1e6;
    font-size: 14.4px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #312025;
    border-bottom-color: transparent;
  }

  .ant-table-cell-row-hover {
    background: #fef1e6 !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-size: 14.4px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      color: #92616f;
      border-bottom-color: #f5b5c7;
    }
  }

  // .ant-table-row-level-0 {
  //   // .ant-table-cell {
  //   //   font-size: 18px;
  //   // }
  //   & :last-child {
  // // text-align: end;
  // color: #61414a;
  // font-family: Lato;
  // font-size: 14.4px;
  // font-style: normal;
  // font-weight: 500;
  // line-height: 150%;
  //   }
  // }

  .table-amount-align {
    text-align: end;
    color: #61414a;
    font-family: Lato;
    font-size: 14.4px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .ant-table-tbody :nth-last-child(2) {
    // display: none;
    .ant-table-cell {
      border-bottom-color: #f5b5c7;
    }
  }

  .ant-table-footer {
    background: #fef1e6;
    margin-top: -1.5rem;
  }
  .table-footer {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    text-align: end;
    .total-payment {
      span {
        color: #92616f;
      }
      font-size: 48px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .label-left-to-pay {
      margin-top: 1rem;
      color: #92616f;
    }
    .left-to-pay-amount {
      color: #92616f;
      font-size: 24px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .ant-btn {
      margin-top: 1rem;
    }
  }

  .ant-row {
    gap: 0.5rem;

    .ant-btn {
      padding: 24px 16px;
    }

    .ant-btn-default {
      background: transparent;
    }
  }
}

@media (max-width: 576px) {
  .case-details {
    div:last-of-type {
      font-size: 18px;
    }
  }
  .customise-payments {
    strong {
      font-weight: 800 !important;
    }
    .table-payment-plan-card {
      .ant-table-row-expand-icon-cell {
        width: 0%;
      }

      // .cell-balance td:nth-child(2) {
      //   font-weight: 800;
      //   font-size: 16.4px;
      // }

      .ant-table-row-level-0 {
        // .ant-table-cell {
        //   font-size: 18px;
        // }
        & :last-child {
          text-align: start;
          color: #61414a;
          font-family: Lato;
          font-size: 14.4px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
    .align-end {
      td {
        text-align: end !important;
      }
    }
  }
}
