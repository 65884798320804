@import "./variables";

.ant-btn span {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.header {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}