.login,
.security-check {  
  height: 100vh;

  .login-page,
  .security-check-page {
    background: #fff;
    display: flex;
    .login-form,
    .security-check-form {
      .login-page--header,
      .security-check--header {
        width: 100%;
        align-self: center;
        padding: 0 100px;

        .question-icon {
          margin-left: 0.4rem;
          cursor: pointer;
        }

        .input-divider {
          margin-top: 2.5rem;
          margin-bottom: 1.5rem;
          hr {
            width: 40%;
            border: 1px solid #c28294;
          }
          & hr:first-of-type {
            margin-left: 0;
          }
          & hr:last-of-type {
            margin-right: 0;
          }
          span {
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 11.52px;
            line-height: 150%;
            color: #c28294;
          }
        }
        .primary-case {
          color: #61414a;
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14.4px;
          line-height: 150%;
        }
      }
      .ant-form {
        margin-top: 3.5rem;
        .ant-form-item-label label {
          color: #61414a;
          font-family: "Lato", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14.4px;
          line-height: 150%;
        }
      }
    }
    .login-page--header {
      margin-top: -10rem;
    }
  }

  .login-artwork {
    display: flex;
    h1,
    h3 {
      position: absolute;
      top: 10%;
      white-space: break-spaces;
      padding: 12%;
      align-self: center;
      color: #ffff;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 54.936px;
      line-height: 125%;
    }
    svg {
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 1%;
    }
  }
}

.logout-btn {
  cursor: pointer;
}

.ant-popover {
  margin-top: 10rem;
  width: 90%;
  .ant-popover-content {
    margin-top: 4rem;
  }
}

.login-case-details div {
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.ant-modal {
  width: 60% !important;
  .slick-arrow {
    color: #000;
  }
}

@media (max-width: 576px) {
  .login,
  .security-check {
    height: 100%;
    .login-page,
    .security-check-page {
      .login-form,
      .security-check-form {
        .login-page--header,
        .security-check--header {
          padding: 50px;
          padding-top: 1rem;
        }
      }
      .login-page--header {
        margin-top: unset;
      }
    }
    .login-artwork {
      display: none;
    }
  }

  .ant-modal {
    width: 100% !important;
  }
}

.input-case-id {
  padding: 17px;
}

.btn-continue {
  width: 100%;
  padding: 29px;
  font-weight: 500;
}
