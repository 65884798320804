.direct-payments {
  background: white;
  justify-content: center;
  margin-top: 2rem;

  .payment-card {
    margin-top: 3.25rem;
    background: #fef1e6;
    border-radius: 2px;

    .ant-card-body {
      display: flex;
      flex-direction: column;
    }

    .payment-text {
      color: #92616f;
      font-size: 14.4px;
    }

    .pay-input {
      margin-top: 1rem;
      padding: 0.5rem;
      width: 50%;

      .ant-input-number-in-form-item {
        margin-top: 0rem !important;
        width: 100% !important;
      }
    }

    .payment-text-left {
      margin-bottom: 2rem;
      span {
        color: #92616f;
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    h1 {
      margin-top: 0;
      color: #312025;
      span:first-child {
        font-size: 48px;
        color: #92616f;
      }
      span:last-child {
        font-size: 32px;
        color: #312025;
      }
    }

    .ant-row {
      gap: 1.5rem;

      .ant-btn {
        padding: 24px 16px;
      }

      .ant-btn-default {
        background: transparent;
      }
    }

    .sumbit-btn {
      margin-bottom: 0;
    }
  }
}

.plan-underpay-modal {
  .ant-modal-content {
    border-radius: 5px;
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
    }
  }
}

@media (max-width: 576px) {
  .direct-payments {
    .payment-card {
      margin: 3rem auto;

      .ant-row {
        gap: 1.5rem;
        justify-content: center;
      }
    }
  }
}
