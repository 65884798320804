.ant-layout {
  background: #fff;
}

.ant-layout-content {
  background: #fff;
}

.logo {
  margin-left: 2rem;
  margin-top: 1rem;
  z-index: 99;
}

.nav-bar {
  font-weight: 500;
  display: flex;
  align-items: center;
  border-bottom: 1px;
  padding: 10px;
  border-bottom-color: #f5b5c7;
  border-bottom-style: solid;
  font-size: 18px;
  background: white;

  .logo {
    margin: 0 2rem;
  }

  .ant-anchor {
    width: 100%;
  }

  .ant-anchor-link {
    .ant-anchor-link-title {
      height: 20px;
    }
    margin-left: 1.5rem;
  }

  .ant-anchor-wrapper-horizontal::before {
    opacity: 0;
  }

  .ant-dropdown-trigger {
    color: #a381b7;
    position: absolute;
    right: 15%;
  }

  .logout-btn {
    color: #a381b7;
    position: absolute;
    right: 5%;
  }

  .language-dropdown svg {
    display: flex;
  }
}

@media (max-width: 576px) {
  .nav-bar {
    display: none;
  }

  .logo {
    margin-top: 2px;
    margin-left: 1rem;
  }

  .sidebar-trigger {
    width: 2.5rem;
    position: fixed;
    right: 0;
    z-index: 100;
  }

  .sidebar {
    .ant-drawer-wrapper-body {
      background: #fef1e6;
    }
    .ant-drawer-body {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 3rem;
      a {
        color: #a381b7;
        font-size: 2rem;
      }
    }
    .ant-drawer-header {
      padding: 24px 24px;
      .ant-drawer-close {
        position: absolute;
        right: 1rem;
      }
    }
  }

  .ant-layout-sider-zero-width-trigger {
    background: #652d87 !important;
    top: 0px !important;
  }

  .ant-layout-sider {
    height: 100vh;

    .ant-layout-sider-children {
      background: #fef1e6;
      .ant-menu {
        background: #fef1e6;
        color: #312025;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav-bar .ant-anchor-link {
    margin-left: 0rem;
  }
}
